/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Answer Option Model Object
 *
 * @export
 * @class OnboardingSurveyAnswerOption
 */
@Serializable
export class OnboardingSurveyAnswerOption {
  @JsonProperty('key')
  public key: string = undefined;

  @JsonProperty('value')
  public value: string = undefined;

  /**
   * Creates an instance of OnboardingSurveyAnswerOption.
   * @param {Array<Partial<OnboardingSurveyAnswerOption>>} args
   * @memberof OnboardingSurveyAnswerOption
   */
  constructor(...args: Array<Partial<OnboardingSurveyAnswerOption>>) {
    Object.assign(this, ...args);
  }
}
