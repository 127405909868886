/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AvailabilityStatus, enumConverterFactory } from '@ws1c/intelligence-models';

/**
 * Tenant Name Availability Status
 *
 * @export
 * @class TenantNameAvailabilityStatus
 */
@Serializable
export class TenantNameAvailabilityStatus {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty({ name: 'status', customConverter: enumConverterFactory(AvailabilityStatus) })
  public status: AvailabilityStatus = undefined;

  /**
   * Initializes instance of TenantNameAvailabilityStatus
   * @param {Array<Partial<TenantNameAvailabilityStatus>>} args - object initialization arguments
   * @memberof TenantNameAvailabilityStatus
   */
  constructor(...args: Array<Partial<TenantNameAvailabilityStatus>>) {
    Object.assign(this, ...args);
  }
}
