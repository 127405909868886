/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, Serializable } from '@dpa/ui-common';

/**
 * OnboardingSurveyRequest
 * @export
 * @class OnboardingSurveyRequest
 * @extends {PagedRequest}
 */
@Serializable
export class OnboardingSurveyRequest extends PagedRequest {
  /**
   * Creates an instance of OnboardingSurveyRequest.
   * @param {Array<Partial<OnboardingSurveyRequest>>} args
   * @memberof OnboardingSurveyRequest
   */
  constructor(...args: Array<Partial<OnboardingSurveyRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}
