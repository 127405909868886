/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter, UserDetails } from '@ws1c/intelligence-models';

/**
 * Tenant Url Update Status
 *
 * @export
 * @class TenantUrlUpdateStatus
 */
@Serializable
export class TenantUrlUpdateStatus {
  @JsonProperty('integration_id')
  public integrationId: string = undefined;

  @JsonProperty('status')
  public status: string = undefined;

  @JsonProperty('message')
  public message: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails })
  public modifiedByDetails: UserDetails = undefined;

  /**
   * Initializes instance of TenantUrlUpdateStatus
   * @param {Array<Partial<TenantUrlUpdateStatus>>} args - object initialization arguments
   * @memberof TenantUrlUpdateStatus
   */
  constructor(...args: Array<Partial<TenantUrlUpdateStatus>>) {
    Object.assign(this, ...args);
  }
}
