/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { OnboardingSurveyAnswerOption } from './onboarding-survey-answer-option.model';

/**
 * OnboardingSurveyAnswer Model
 *
 * @export
 * @class OnboardingSurveyAnswer
 */
@Serializable
export class OnboardingSurveyAnswer {
  @JsonProperty('question_id')
  public questionId: string = undefined;

  @JsonProperty('text_response')
  public textResponse: string = undefined;

  @JsonProperty({ name: 'selected', cls: OnboardingSurveyAnswerOption })
  public selected: OnboardingSurveyAnswerOption[] = [];

  /**
   * Creates an instance of OnboardingSurveyAnswer
   * @param {Array<Partial<OnboardingSurveyAnswer>>} args - Initialization arguments for OnboardingSurveyAnswer
   * @memberof OnboardingSurveyAnswer
   */
  constructor(...args: Array<Partial<OnboardingSurveyAnswer>>) {
    Object.assign(this, ...args);
  }
}
