/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse } from '@dpa/ui-common';

import { OnboardingSurveyQuestions } from './onboarding-survey-questions.model';

/**
 * OnboardingSurveyResponse
 * @export
 * @class OnboardingSurveyResponse
 * @implements {PagedResponse}
 */
export class OnboardingSurveyResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: OnboardingSurveyQuestions })
  public results: OnboardingSurveyQuestions[] = [];

  /**
   * Creates an instance of OnboardingSurveyResponse.
   * @param {Array<Partial<OnboardingSurveyResponse>>} args
   * @memberof OnboardingSurveyResponse
   */
  constructor(...args: Array<Partial<OnboardingSurveyResponse>>) {
    Object.assign(this, ...args);
  }
}
