/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import {
  OnboardingSurveyAnswerOption,
  OnboardingSurveyQuestions,
  OnboardingSurveyResponse,
} from '@ws1c/intelligence-onboarding/models/onboarding-survey';

/**
 * Onboarding Survey Actions
 *
 * @export
 * @class OnboardingSurveyActions
 */
export class OnboardingSurveyActions {
  public static readonly CATEGORY = '[SURVEY]';

  public static getSurveyQuestions = createAction(
    `${OnboardingSurveyActions.CATEGORY} GET_SURVEY_QUESTIONS`,
    props<{ surveyType: string }>(),
  );

  public static getSurveyQuestionsSuccess = createAction(
    `${OnboardingSurveyActions.CATEGORY} GET_SURVEY_QUESTIONS_SUCCESS`,
    props<{ surveyQuestionsResponse: OnboardingSurveyResponse }>(),
  );

  public static saveAnswer = createAction(`${OnboardingSurveyActions.CATEGORY} SAVE_ANSWER`, props<{ questionOrderToBeSaved: number }>());

  public static saveAnswerSuccess = createAction(
    `${OnboardingSurveyActions.CATEGORY} SAVE_ANSWER_SUCCESS`,
    props<{ surveyQuestions: OnboardingSurveyQuestions }>(),
  );

  public static saveAnswerFailure = createAction(`${OnboardingSurveyActions.CATEGORY} SAVE_ANSWER_FAILURE`);

  public static setCurrentStepNo = createAction(
    `${OnboardingSurveyActions.CATEGORY} SET_CURRENT_STEP_NO`,
    props<{ currentStepNo: number }>(),
  );

  public static setCurrentSelectedAnswerOptions = createAction(
    `${OnboardingSurveyActions.CATEGORY} SET_CURRENT_SELECTED_ANSWER_OPTIONS`,
    props<{ currentSelectedAnswerOptions: OnboardingSurveyAnswerOption[] }>(),
  );

  public static setCustomAnswer = createAction(`${OnboardingSurveyActions.CATEGORY} SET_TEXT_RESPONSE`, props<{ customAnswer: string }>());
}
