/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, createReducer, on } from '@ngrx/store';

import { LOAD_STATE } from '@ws1c/intelligence-models';
import { OnboardingSurveyState } from '@ws1c/intelligence-onboarding/models/onboarding-survey/onboarding-survey-state.interface';
import { OnboardingSurveyActions } from './onboarding-survey.actions';
import { initialSurveyState } from './onboarding-survey.state';

/**
 * _reducer
 * @type {ActionReducer<OnboardingSurveyState, Action>}
 */
const _reducer = createReducer(
  initialSurveyState,

  on(
    OnboardingSurveyActions.getSurveyQuestions,
    (state: OnboardingSurveyState): OnboardingSurveyState => ({
      ...state,
    }),
  ),

  on(
    OnboardingSurveyActions.getSurveyQuestionsSuccess,
    (
      state: OnboardingSurveyState,
      { surveyQuestionsResponse }: ReturnType<typeof OnboardingSurveyActions.getSurveyQuestionsSuccess>,
    ): OnboardingSurveyState => ({
      ...state,
      surveyQuestions: surveyQuestionsResponse.results?.[0],
      numberOfAnsweredQuestions: surveyQuestionsResponse?.results?.[0]?.numberOfAnsweredQuestions,
    }),
  ),

  on(
    OnboardingSurveyActions.saveAnswer,
    (state: OnboardingSurveyState): OnboardingSurveyState => ({
      ...state,
      saveAnswerStartState: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    OnboardingSurveyActions.saveAnswerSuccess,
    (
      state: OnboardingSurveyState,
      { surveyQuestions }: ReturnType<typeof OnboardingSurveyActions.saveAnswerSuccess>,
    ): OnboardingSurveyState => ({
      ...state,
      saveAnswerStartState: LOAD_STATE.SUCCESS,
      surveyQuestions,
    }),
  ),

  on(
    OnboardingSurveyActions.saveAnswerFailure,
    (state: OnboardingSurveyState): OnboardingSurveyState => ({
      ...state,
      saveAnswerStartState: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    OnboardingSurveyActions.setCurrentStepNo,
    (
      state: OnboardingSurveyState,
      { currentStepNo }: ReturnType<typeof OnboardingSurveyActions.setCurrentStepNo>,
    ): OnboardingSurveyState => ({
      ...state,
      currentStepNo,
    }),
  ),

  on(
    OnboardingSurveyActions.setCurrentSelectedAnswerOptions,
    (
      state: OnboardingSurveyState,
      { currentSelectedAnswerOptions }: ReturnType<typeof OnboardingSurveyActions.setCurrentSelectedAnswerOptions>,
    ): OnboardingSurveyState => ({
      ...state,
      currentSelectedAnswerOptions,
    }),
  ),

  on(
    OnboardingSurveyActions.setCustomAnswer,
    (
      state: OnboardingSurveyState,
      { customAnswer }: ReturnType<typeof OnboardingSurveyActions.setCustomAnswer>,
    ): OnboardingSurveyState => ({
      ...state,
      customAnswer,
    }),
  ),
);

/**
 * Creates and returns OnboardingSurveyState
 * @export
 * @param {OnboardingSurveyState} state - Holds state object of type OnboardingSurveyState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {OnboardingSurveyState}
 */
export function onboardingSurveyReducer(state: OnboardingSurveyState, action: Action): OnboardingSurveyState {
  return _reducer(state, action);
}
