/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { LOAD_STATE } from '@ws1c/intelligence-models';
import { OnboardingSurveyQuestions, OnboardingSurveyState } from '@ws1c/intelligence-onboarding/models/onboarding-survey';

/**
 * Defines initial state for Onboarding Survey
 *
 * @export
 * @type {OnboardingSurveyState}
 */
export const initialSurveyState: OnboardingSurveyState = {
  currentStepNo: 0,
  currentSelectedAnswerOptions: [],
  customAnswer: '',
  numberOfAnsweredQuestions: 0,
  saveAnswerStartState: LOAD_STATE.NONE,
  surveyQuestions: new OnboardingSurveyQuestions(),
};
