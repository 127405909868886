/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Tenant Reprovisioning Flow
 *
 * @export
 * @enum {string}
 */
export enum TenantReprovisioningFlow {
  INTEGRATION_CARD = 'INTEGRATION_CARD',
  ONBOARDING = 'ONBOARDING',
}

/**
 * Tenant Reprovisioning Status
 *
 * @export
 * @enum {string}
 */
export enum TenantReprovisioningStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
  QUEUED = 'QUEUED',
}
