/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { OnboardingSurveyQuestion } from './onboarding-survey-question.model';

/**
 * Onboarding Survey Questions
 * @export
 * @class OnboardingSurveyQuestions
 */
@Serializable
export class OnboardingSurveyQuestions {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty({ name: 'questions', cls: OnboardingSurveyQuestion })
  public questions: OnboardingSurveyQuestion[] = [];

  /**
   * numberOfAnsweredQuestions
   * @returns {number}
   * @memberof OnboardingSurveyQuestions
   */
  public get numberOfAnsweredQuestions(): number {
    let lastAnsweredQuestionIndex: number = -1;
    for (let questionIndex: number = 0; questionIndex < this.questions.length; questionIndex++) {
      if (this.questions[questionIndex].defaultAnswer) {
        lastAnsweredQuestionIndex = questionIndex;
      }
    }
    return lastAnsweredQuestionIndex + 1;
  }

  /**
   * Creates an instance of OnboardingSurveyQuestions
   * @param {Array<Partial<OnboardingSurveyQuestions>>} args - Initialization arguments for OnboardingSurveyQuestions
   * @memberof OnboardingSurveyQuestions
   */
  constructor(...args: Array<Partial<OnboardingSurveyQuestions>>) {
    Object.assign(this, ...args);
  }
}
