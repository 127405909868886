/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * SurveyQuestion Type
 *
 * @export
 * @enum {SurveyQuestionType}
 */
export enum SurveyQuestionType {
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
}
