/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Tenant Users Detail
 *
 * @export
 * @class TenantUsersDetail
 */
@Serializable
export class TenantUsersDetail {
  @JsonProperty('users_count')
  public usersCount: number = undefined;

  /**
   * Initializes instance of TenantUsersDetail
   * @param {Array<Partial<TenantUsersDetail>>} args - object initialization arguments
   * @memberof TenantUsersDetail
   */
  constructor(...args: Array<Partial<TenantUsersDetail>>) {
    Object.assign(this, ...args);
  }
}
