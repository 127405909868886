/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Tenant Url Rename Request
 *
 * @export
 * @class TenantUrlRenameRequest
 */
@Serializable
export class TenantUrlRenameRequest {
  @JsonProperty('flow_name')
  public flowName: string = undefined;

  @JsonProperty('new_tenant_name')
  public newTenantName: string = undefined;

  @JsonProperty('old_tenant_url')
  public oldTenantUrl: string = undefined;

  /**
   * Initializes instance of TenantUrlRenameRequest
   * @param {Array<Partial<TenantUrlRenameRequest>>} args - object initialization arguments
   * @memberof TenantUrlRenameRequest
   */
  constructor(...args: Array<Partial<TenantUrlRenameRequest>>) {
    Object.assign(this, ...args);
  }
}
