/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { OnboardingSurveyAnswerOption } from './onboarding-survey-answer-option.model';
import { OnboardingSurveyAnswer } from './onboarding-survey-answer.model';

/**
 * Onboarding Survey Question Model
 *
 * @export
 * @class OnboardingSurveyQuestion
 */
@Serializable
export class OnboardingSurveyQuestion {
  @JsonProperty('question_id')
  public questionId: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('format')
  public format: string = undefined;

  @JsonProperty({ name: 'default_answer', cls: OnboardingSurveyAnswer })
  public defaultAnswer?: OnboardingSurveyAnswer = undefined;

  @JsonProperty('required')
  public required: boolean = true;

  @JsonProperty('order')
  public order: number = undefined;

  @JsonProperty({ name: 'values', cls: OnboardingSurveyAnswerOption })
  public values: OnboardingSurveyAnswerOption[] = [];

  /**
   * Creates an instance of OnboardingSurveyQuestion
   * @param {Array<Partial<OnboardingSurveyQuestion>>} args - Initialization arguments for OnboardingSurveyQuestion
   * @memberof OnboardingSurveyQuestion
   */
  constructor(...args: Array<Partial<OnboardingSurveyQuestion>>) {
    Object.assign(this, ...args);
  }
}
